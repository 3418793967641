/**
 * @name 通知列表
 */
import request from '@/utils/request'

export const getNotificationsSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/notifications/setup'
  })
}

export const getNotificationsList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/notifications',
    params
  })
}

export const getNotificationsDetails = (id) => {
  return request({
    method: 'GET',
    url: `/cms/notifications/${id}`
  })
}

export const getNotificationsCollections = () => {
  return request({
    method: 'GET',
    url: '/cms/notifications/collections'
  })
}

export const postNotificationsFormData = (data) => {
  return request({
    method: 'POST',
    url: 'cms/notifications',
    data
  })
}
