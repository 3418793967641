// import i18n from '@/i18n/index'

export default {
  basicInformation: [{
    prop: 'notifiable_type'
  }, {
    prop: 'user'
  }, {
    prop: 'cover_image',
    image: true
  }, {
    prop: 'button_text'
  }, {
    prop: 'button_link'
  }, {
    prop: 'published_at'
  }, {
    prop: 'short_description'
  }, {
    prop: 'description'
  }, {
    prop: 'icon',
    image: true
  }, {
    prop: 'title'
  }, {
    prop: 'created_at'
  }, {
    prop: 'updated_at'
  }, {
    prop: 'deleted_at'
  }, {
    prop: 'notification_type'
  }, {
    prop: 'read_at'
  }, {
    prop: 'sub_type'
  }, {
    prop: 'target_type'
  }, {
    prop: 'target_id'
  }, {
    prop: 'push_type'
  }, {
    prop: 'scheduled_time'
  }]
}
